$(document).on('turbolinks:load', (event) => {
  console.log('load common.js');

  // rails-ujs Ajax events
  document.body.addEventListener('ajax:send', (event) => {
    console.log('ajax:send');
    $('#indicator').show();
  });
  document.body.addEventListener('ajax:complete', (event) => {
    console.log('ajax:complete');
    $('#indicator').hide();
  });
});
